import { IconButton, TextFieldProps } from "@mui/material";
import { useState } from "react";
import CustomTextField from "../CustomTextField";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

const PasswordTextField = (props: TextFieldProps) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <CustomTextField
      {...props}
      type={passwordVisible ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <IconButton
            onClick={() => setPasswordVisible(!passwordVisible)}
            sx={{ marginRight: "-8px" }}
          >
            {passwordVisible ? (
              <VisibilityOffOutlinedIcon />
            ) : (
              <VisibilityOutlinedIcon />
            )}
          </IconButton>
        ),
      }}
    />
  );
};

export default PasswordTextField;
