import { Box } from "@mui/material";
import Layout from "containers/Layout";
import type { GetServerSideProps, NextPage } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import styles from "./Login.module.scss";
import LoginForm from "components/forms/auth/LoginForm";
import PageTitle from "components/PageTitle";

const Login: NextPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <PageTitle title={t("login")} />

      <Box className={styles.root}>
        <LoginForm />
      </Box>
    </Layout>
  );
};

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale!, ["common"])),
    },
  };
};

export default Login;
