import { styled, TextField } from "@mui/material";

const CustomTextField = styled(TextField)((props) => ({
  "& .MuiInputBase-root": {
    borderRadius: 8,
  },
  "& .MuiInputBase-input": {
    padding: "20px",
    fontSize: "18px",
    lineHeight: "1.33em",

    [props.theme.breakpoints.down("sm")]: {
      padding: "12px",
      fontSize: "16px",
      lineHeight: "1.5em",
    },
  },
}));

export default CustomTextField;
