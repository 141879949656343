import { Box, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import Link from "next/link";
import { FC, useState } from "react";
import styles from "./LoginForm.module.scss";
import { useTranslation } from "next-i18next";
import { loginFormSchema } from "schemas/auth.schema";
import { useRouter } from "next/router";
import { signIn } from "next-auth/react";
import { useSnackbar } from "notistack";
import EmailTextField from "components/inputs/EmailTextField";
import PasswordTextField from "components/inputs/PasswordTextField";

const LoginForm: FC = () => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const [isRequesting, setIsRequesting] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const form = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginFormSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      const { email, password } = values;

      setIsRequesting(true);
      const res = await signIn("credentials", {
        email,
        password,
        redirect: false,
      });

      if (res?.ok) {
        closeSnackbar();
        router.push("/profile");
        return;
      }

      if (res?.error) {
        const snackbarVariant =
          res.error === "request_on_moderation_detail" ? "warning" : "error";
        enqueueSnackbar(t(res.error), {
          variant: snackbarVariant,
        });
        setIsRequesting(false);
      }
    },
  });

  return (
    <Box className={styles.root}>
      <form className={styles.form} onSubmit={form.handleSubmit}>
        <Typography className={styles.caption}>{t("login")}</Typography>

        <Typography className={styles.tip}>{t("login_caption")}</Typography>

        <label htmlFor="email" className={styles.label}>
          {t("email")}
        </label>

        <EmailTextField
          id="email"
          name="email"
          value={form.values.email}
          placeholder={t("email")}
          onChange={(e) => {
            const value = e.target.value || "";
            form.setFieldValue("email", value.toLowerCase());
          }}
          onBlur={form.handleBlur}
          error={form.touched.email && Boolean(form.errors.email)}
          helperText={
            form.touched.email && form.errors.email && t(form.errors.email!)
          }
          className={styles.textfield}
        />

        <label htmlFor="password" className={styles.label}>
          {t("password")}
        </label>
        <PasswordTextField
          id="password"
          name="password"
          value={form.values.password}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          placeholder={t("password")}
          className={styles.textfield}
          error={form.touched.password && Boolean(form.errors.password)}
          helperText={form.touched.password && t(form.errors.password!)}
        />

        <LoadingButton
          classes={{
            root: styles.submit,
            disabled: styles.submit_disabled,
          }}
          type="submit"
          fullWidth
          disabled={!form.isValid}
          loading={isRequesting}
        >
          {t("sign_in")}
        </LoadingButton>

        <Typography className={styles.helper}>
          {t("forgot_password")}?{" "}
          <Link href="/restore">
            <a>{t("restore")}</a>
          </Link>
        </Typography>
        <Typography className={styles.helper}>
          {t("no_account")}?{" "}
          <Link href="/register">
            <a>{t("register_")}</a>
          </Link>
        </Typography>
      </form>
    </Box>
  );
};

export default LoginForm;
