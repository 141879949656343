import { TextFieldProps } from "@mui/material";
import CustomTextField from "../CustomTextField";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";

const EmailTextField = (props: TextFieldProps) => {
  return (
    <CustomTextField
      {...props}
      type="email"
      inputMode="email"
      InputProps={{
        endAdornment: <MailOutlineRoundedIcon sx={{ color: "#828282" }} />,
      }}
    />
  );
};

export default EmailTextField;
