import * as yup from "yup";

export const registrationFullFormSchema = yup.object().shape({
  email: yup.string().email("must_be_correct_email").required("required_field"),
  first_name: yup.string().required("required_field").max(30),
  last_name: yup.string().required("required_field").max(30),
  birthdate: yup.string().required("required_field").nullable(),
  phone_number: yup.string(),
  password: yup
    .string()
    .required("required_field")
    .min(8, "must_be_at_least_8_characters"),
  agreed: yup.boolean().oneOf([true], "must_be_checked"),
});

export const loginFormSchema = yup.object().shape({
  email: yup.string().email("must_be_correct_email").required("required_field"),
  password: yup.string().required("required_field"),
});

export const restorePasswordFormSchema = yup.object().shape({
  email: yup.string().email().required("required_field"),
});

export const resetPasswordFormSchema = yup.object().shape({
  password: yup
    .string()
    .required("required_field")
    .min(8, "must_be_at_least_8_characters"),
});
